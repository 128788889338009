// Teaser

$teaser-spacer: 5px;

.teaser-grid {
    margin: -$teaser-spacer;

    @include media-breakpoint-up(md) {
        margin: -($teaser-spacer*2);
    }

    &.layout-2-columns,
    &.layout-3-columns {
        display: flex;
        flex-wrap: wrap;
    }


    &.layout-2-columns {
        .teaser {
            &:nth-child(odd) {
                @include media-breakpoint-up(sm) {
                    width: 66.66666%;

                    &::before {
                        padding-top: 50%;
                    }
                }
            }
        }

        .teaser:nth-child(even) {
            @include media-breakpoint-up(sm) {
                width: 33.33333%;
            }
        }
    }

    &.layout-3-columns {
        .teaser {
            @include media-breakpoint-up(sm) {
                width: 33.33333%;
            }
        }
    }

    &.layout-grid {
        display: -ms-grid;
        display: grid;
        -ms-grid-rows: auto auto auto auto auto;
        grid-template-rows: auto auto auto auto auto;

        @include media-breakpoint-up(sm) {
            -ms-grid-columns: 33.33333% 33.33333% 33.33333%;
            grid-template-columns: 33.33333% 33.33333% 33.33333%;
            -ms-grid-rows: auto auto auto;
            grid-template-rows: auto auto auto;
        }

        .teaser {
            width: 100%;
            @include media-breakpoint-up(sm) {
                &.gridcol-1 {
                    -ms-grid-column: 2;
                    -ms-grid-column-span: 2;
                    grid-column: 2/span 2;
                    -ms-grid-row: 2;
                    -ms-grid-row-span: 2;
                    grid-row: 2/span 2;
                }
                &.gridcol-2 {
                    -ms-grid-column: 1;
                    -ms-grid-column-span: 2;
                    grid-column: 1/span 2;
                    -ms-grid-row: 1;
                    -ms-grid-row-span: 1;
                    grid-row: 1/span 1;

                    &::before {
                        padding-top: 50%;
                    }
                }
                &.gridcol-3 {
                    -ms-grid-column: 3;
                    -ms-grid-column-span: 1;
                    grid-column: 3/span 1;
                    -ms-grid-row: 1;
                    -ms-grid-row-span: 1;
                    grid-row: 1/span 1;
                }
                &.gridcol-4 {
                    -ms-grid-column: 1;
                    -ms-grid-column-span: 1;
                    grid-column: 1/span 1;
                    -ms-grid-row: 2;
                    -ms-grid-row-span: 1;
                    grid-row: 2/span 1;
                }
                &.gridcol-5 {
                    -ms-grid-column: 1;
                    -ms-grid-column-span: 1;
                    grid-column: 1/span 1;
                    -ms-grid-row: 3;
                    -ms-grid-row-span: 1;
                    grid-row: 3/span 1;
                }
            }
        }
    }
}

.teaser {
    position: relative;
    width: 100%;

    &::before {
        content: '';
        display: block;
        padding-top: 100%;
    }

    .teaser-inner {
        position: absolute;
        top: $teaser-spacer;
        left: $teaser-spacer;
        right: $teaser-spacer;
        bottom: $teaser-spacer;
        overflow: hidden;

        &.color {
            background-color: $primary;
        }

        &.color_darkgrey {
            background-color: $darkgray;
        }

        &.color_lightgrey {
            background-color: $lightgray;
        }

        &.color_gold {
            background-color: $gold;
        }

        &.wave {
            background-image: $background-wave;
            background-size: 1000px;
            background-position: 0;
            transition: background-position ($transition-time) $transition-ease;

            &:hover {
                background-position: -120px;
                transition: background-position ($transition-time) $transition-ease;
            }
        }

        @include media-breakpoint-up(md) {
            top: ($teaser-spacer*2);
            left: ($teaser-spacer*2);
            right: ($teaser-spacer*2);
            bottom: ($teaser-spacer*2);
        }

        > a {
            display: block;
            width: 100%;
            height: 100%;
        }

        .teaser-img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            transition: $btn-transition;
        }

        .teaser-title {
            position: absolute;
            line-height: 1;
            text-transform: uppercase;
            left: 0;
            bottom: $teaser-spacer*3;
            padding: 0 $teaser-spacer*3;
            font-family: $headings-font-family;

            &.white {
                color: $white;

                &::after {
                    background: $white;
                }
            }

            &.dark {
                color: $dark;

                &::after {
                    background: $dark;
                }
            }

            @include fluid-type($teaser-font-size, $teaser-font-size-xl);

            &::after {
                content: '';
                display: block;
                position: relative;
                transition: $btn-transition;
                width: 75%;
                height: 1px;
            }
        }
    }

    @include hover() {

        .teaser-img {
            transform: scale(1.025, 1.025);
        }

        .teaser-inner .teaser-title::after {
            width: 100%;
        }
    }
}
