//https://css-tricks.com/snippets/css/fluid-typography/
@mixin fluid-type($min-value, $max-value, $breakpoint-min:sm, $breakpoint-max:xl) {
    & {
        font-size: $min-value;

        @include media-breakpoint-up($breakpoint-min) {
            font-size: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * ((100vw - #{breakpoint-rem($breakpoint-min)}rem) / #{strip-unit(breakpoint-rem($breakpoint-max) - breakpoint-rem($breakpoint-min))}));
        }

        @include media-breakpoint-up($breakpoint-max) {
            font-size: $max-value;
        }
    }
}


@function breakpoint-rem($breakpoint) {
    @return strip-unit(breakpoint-min($breakpoint) / 16);
}
@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

@mixin custom-form-validation-state($state, $color, $icon) {
    /*.#{$state}-feedback {
        display: none;
        width: 100%;
        margin-top: $form-feedback-margin-top;
        @include font-size($form-feedback-font-size);
        color: $color;
    }

    @include form-validation-state-selector($state) {
        ~ .#{$state}-feedback,
        ~ .#{$state}-tooltip {
            display: block;
        }
    }*/

    .form-control {
        @include form-validation-state-selector($state) {
            padding-right: $input-padding-x * 2;
            background-image: escape-svg($icon);
            background-repeat: no-repeat;
            background-position: right $input-padding-x center;
            background-size: 1rem 1rem;


            @include media-breakpoint-up(lg) {
                padding-right: $input-padding-x-lg * 2;
                background-position: right $input-padding-x-lg center;
            }
        }
    }

    // stylelint-disable-next-line selector-no-qualifying-type
    textarea.form-control {
        @include form-validation-state-selector($state) {
            padding-right: $input-padding-x * 2;
            background-position: top $input-padding-top right $input-padding-x;

            @include media-breakpoint-up(lg) {
                padding-right: $input-padding-x-lg * 2;
                background-position: top $input-padding-top-lg right $input-padding-x-lg;
            }
        }
    }

    .custom-select {
        @include form-validation-state-selector($state) {
            padding-right: $custom-select-feedback-icon-padding-right;
            background: $custom-select-background, escape-svg($icon) $input-bg no-repeat $custom-select-feedback-icon-position / $custom-select-feedback-icon-size;
        }
    }

    .custom-control-input {
        @include form-validation-state-selector($state) {
            //show only invalid state here.. green checkboxes are ugly..
            @if $state == "invalid" {
                ~ .custom-control-label {
                    color: $color;
                }
            }


            /*            &:checked {
                            ~ .custom-control-label::before {
                                border-color: lighten($color, 10%);
                                @include gradient-bg(lighten($color, 10%));
                            }
                        }

                        &:focus {
                            ~ .custom-control-label::before {
                                box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
                            }

                            &:not(:checked) ~ .custom-control-label::before {
                                border-color: $color;
                            }
                        }*/
        }
    }
}
